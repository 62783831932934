<template>
  <!--begin::Work Orders Calendar-->
  <div class="row g-5 gx-xxl-12 pb-3" style="background: #fff">
    <div class="dateHistogramSelector px-5">
      <HistogramSlider
        :key="histoTempKey"
        :ref="setItemRef"
        :width="1550"
        :bar-height="100"
        :step="31 * 86400 * 1000"
        :bar-radius="3"
        :data="histogramData"
        :prettify="prettifyHistogramData"
        :drag-interval="true"
        :force-edges="true"
        :hide-from-to="true"
        :primary-color="`#1b9931`"
        :label-color="`#1b9931`"
        :colors="['#1b9931', '#134d88']"
        :min="minHistogramValue"
        :max="maxHistogramValue"
        @finish="histogramValue"
      />
    </div>

    <FullCalendar
      class="demo-app-calendar"
      :options="calendarOptions"
      :ref="setItemRef"
    >
      ></FullCalendar
    >
  </div>
  <!--end::Work Orders Calendar-->
</template>
<style>
.irs-handle {
  display: none !important; /*tmp hide*/
}

.dateHistogramSelector {
  overflow: hidden;
}

.fc-event-info .fc-list-event-dot{
  border-color: #05a9ed;
}
.fc-event-danger .fc-list-event-dot{
  border-color: #980000;
}
</style>
<script lang="ts">
import { defineComponent, ref, onMounted, getCurrentInstance } from "vue";
import FullCalendar, {
  Calendar,
  CalendarOptions,
  EventApi
} from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import HistogramSlider from "vue-histogram-slider/src/lib/HistogramSlider.vue";
import "vue-histogram-slider/dist/histogram-slider.css";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";
import { emitter } from "@/main";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "maintenance-schedule-calendar",
  components: {
    FullCalendar,
    HistogramSlider
  },
  methods: {
    histogramValue(val) {
      //console.log("histogram value", val);
      const begin = moment(val.from).format("YYYY-MM-01");
      const end = moment(val.from).format("YYYY-MM-") + moment(val.from).daysInMonth();
      /*emitter.emit("updateHistogramValue", {
        from: moment(begin).valueOf(),
        to: moment(end).valueOf()
      });*/
      this.updateHistogram({
        from: moment(begin).set({
          hour: 0, minute: 0, second: 0
        }).valueOf(),
        to: moment(end).set({
          hour: 23, minute: 59, second: 59
        }).valueOf()
      });

      //console.log(this.itemRefs);
      //update month view to new date
      const calendarApi = (this.itemRefs[1] as any).getApi();
      calendarApi.gotoDate(moment(begin).valueOf());
      calendarApi.changeView("dayGridMonth");
    },
    updateHistogram(values) {
      const histogramSliderApi = this.itemRefs[0] as any;
      histogramSliderApi.update(values);
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    }
  },
  beforeUpdate() {
    this.itemRefs = [];
  },
  created: function() {
    setTimeout(() => {
      //console.log("updating histo");
      this.updateHistogram({
        from: new Date(2022, 0, 1, 0, 0, 0).valueOf(),
        to: new Date(2022, 11, 31, 23, 59, 59).valueOf()
      });
    }, 200);
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Maintenance schedule calendar");
      setCurrentPageBreadcrumbs("Maintenance schedule calendar", ["Maintenance management"]);
      setCurrentPageToolbarType("maintenanceScheduleCalendar");
    });
  },
  data() {
    //const nowIndicatorTime = moment(); //.add(30, "minutes")
    const histoTempKey = 0;
    const minHistogramValue = new Date(2022, 0, 1).valueOf();
    const maxHistogramValue = new Date(2022, 12, 30).valueOf();

    const todayDate = moment().startOf("day");
    const YM = todayDate.format("YYYY-MM");
    const YESTERDAY = todayDate
      .clone()
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    const TODAY = todayDate.format("YYYY-MM-DD");
    const TOMORROW = todayDate
      .clone()
      .add(1, "day")
      .format("YYYY-MM-DD");
    const calendarElements = [{
      id: 0,
      title: "PS-WO-311",
      start: "2021-11-28",
      end: "2021-12-08",
      className: "fc-event-danger"
    }];
    for (let i = 1; i < 18; i++) {
      //const woId = 311 + i;
      const assetsTmp = [
        "BA SA5000",
        "DOOSAN 1060 CFM",
        "AC XAMS 546"
      ];
      const levelsTmp = [
        "A","B","C","D"
      ];
      const statusTmp = [
        "success","info","danger"
      ];

      //var randomItem = assetsTmp[Math.floor(Math.random()*assetsTmp.length)];
      calendarElements.push({
        id: i,
        title: assetsTmp[Math.floor(Math.random()*assetsTmp.length)] + "<br>SN: " + Math.ceil(Math.random() * ( 1 + 9999999 - 1000999 ))
            + "<br>Level "+levelsTmp[Math.floor(Math.random()*levelsTmp.length)],
        start: "2022-10-"+(1+i*3),
        end: "2022-10-"+(5+i*3),
        className: "fc-event-"+statusTmp[Math.floor(Math.random()*statusTmp.length)]
      });
    }
    //console.log(calendarElements);
    const calendarElementsTmp = [
      {
        title: "Previous year demo",
        start: "2021-10-31",
        description: "Toto lorem ipsum dolor sit incid idunt ut",
        className: "fc-event-danger fc-event-solid-warning"
      },
      {
        title: "All Day Event",
        start: YM + "-01",
        description: "Toto lorem ipsum dolor sit incid idunt ut",
        className: "fc-event-danger fc-event-solid-warning"
      },
      {
        title: "Reporting",
        start: YM + "-14T13:30:00",
        description: "Lorem ipsum dolor incid idunt ut labore",
        end: YM + "-14",
        className: "fc-event-success"
      },
      {
        title: "Company Trip",
        start: YM + "-02",
        description: "Lorem ipsum dolor sit tempor incid",
        end: YM + "-03",
        className: "fc-event-primary"
      },
      {
        title: "ICT Expo 2017 - Product Release",
        start: YM + "-03",
        description: "Lorem ipsum dolor sit tempor inci",
        end: YM + "-05",
        className: "fc-event-light fc-event-solid-primary"
      },
      {
        title: "Dinner",
        start: YM + "-12",
        description: "Lorem ipsum dolor sit amet, conse ctetur",
        end: YM + "-10"
      },
      {
        id: 999,
        title: "Repeating Event",
        start: YM + "-09T16:00:00",
        description: "Lorem ipsum dolor sit ncididunt ut labore",
        className: "fc-event-danger"
      },
      {
        id: 1000,
        title: "Repeating Event",
        description: "Lorem ipsum dolor sit amet, labore",
        start: YM + "-16T16:00:00"
      },
      {
        title: "Conference",
        start: YESTERDAY,
        end: TOMORROW,
        description: "Lorem ipsum dolor eius mod tempor labore",
        className: "fc-event-primary"
      },
      {
        title: "Meeting",
        start: TODAY + "T10:30:00",
        end: TODAY + "T12:30:00",
        description: "Lorem ipsum dolor eiu idunt ut labore"
      },
      {
        title: "Lunch",
        start: TODAY + "T12:00:00",
        className: "fc-event-info",
        description: "Lorem ipsum dolor sit amet, ut labore"
      },
      {
        title: "Meeting",
        start: TODAY + "T14:30:00",
        className: "fc-event-warning",
        description: "Lorem ipsum conse ctetur adipi scing"
      },
      {
        title: "Happy Hour",
        start: TODAY + "T17:30:00",
        className: "fc-event-info",
        description: "Lorem ipsum dolor sit amet, conse ctetur"
      },
      {
        title: "Dinner",
        start: TOMORROW + "T05:00:00",
        className: "fc-event-solid-danger fc-event-light",
        description: "Lorem ipsum dolor sit ctetur adipi scing"
      },
      {
        title: "Birthday Party",
        start: TOMORROW + "T07:00:00",
        className: "fc-event-primary",
        description: "Lorem ipsum dolor sit amet, scing"
      },
      {
        title: "Click for Google",
        url: "http://google.com/",
        start: YM + "-28",
        className: "fc-event-solid-info fc-event-light",
        description: "Lorem ipsum dolor sit amet, labore"
      },
      {
        title: "April demo",
        start: "2022-04-09",
        description: "Toto lorem ipsum dolor sit incid idunt ut",
        className: "fc-event-danger fc-event-solid-warning"
      }
    ];

    //console.log(calendarElements.map(d => new Date(d.start).valueOf()));
    const itemRefs: string[] = [];

    return {
      itemRefs,
      histoTempKey,
      minHistogramValue,
      maxHistogramValue,
      prettifyHistogramData: function(ts) {
        return new Date(ts).toLocaleDateString("en", {
          year: "numeric",
          month: "short",
          day: "numeric"
        });
      },
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        customButtons: {
          yearView: {
            text: "Select dates",
            click: function() {
              alert("clicked the custom button!");
            }
          }
        },
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth" //yearView
        },
        height: 800,
        contentHeight: 780,
        aspectRatio: 3,
        nowIndicator: true,
        /* now: function(){
          return moment().add(2, "hours").format("YYYY-MM-DD HH:mm");
        },*/
        eventClick: function(clickInfo) {
          //const eventClass = clickInfo.el.classList[7]);
          if (clickInfo.el.classList[7] !== "fc-event-success") {
            window.open( "https://myassets.az/#/document-db/checklists/view/28517", "_blank");
          }
        },
        eventContent: function( info ) {
          return {html: info.event.title};
        },
        views: {
          dayGridMonth: { buttonText: "month", dayHeaderFormat: { weekday: 'short'}},
          timeGridWeek: { buttonText: "week", dayHeaderFormat: { weekday: 'short', month: 'short', day: 'numeric', omitCommas: false }},
          timeGridDay: { buttonText: "day", dayHeaderFormat: { weekday: 'long'} }
        },

        initialView: "dayGridMonth",
        initialDate: TODAY,

        editable: false,
        dayMaxEvents: false, // allow "more" link when too many events
        navLinks: true,
        weekNumbers: true,
        firstDay: 1,
        expandRows: true,
        dayHeaderFormat: {
          weekday: "long",
          month: "long",
          day: "numeric",
          omitCommas: false
        },
        stickyHeaderDates: false,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false
        },
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false
        },
        businessHours: {
          // days of week. an array of zero-based day of week integers (0=Sunday)
          daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
          startTime: "08:00",
          endTime: "17:00"
        },
        events: calendarElements
      } as CalendarOptions,
      currentEvents: [] as EventApi[],
      histogramData: calendarElements.map(d => new Date(d.start).valueOf())
    };
  }
});
</script>
